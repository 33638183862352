import { pick } from 'lodash';

import {
  RESOURCE_KINDS,
  WORKSPACE_KINDS,
  WORKSPACE_TYPES,
} from '@/config/enums';

import http from '../http';

export interface ResourceSettings {
  isVisible?: boolean;
  showImages?: boolean;
  showDefault?: boolean;
  showCompact?: boolean;
  showSnippet?: boolean;
  showDetails?: boolean;
  isBig?: boolean;
}

export interface Resource {
  resourceId: string;
  title: string;
  resourceKind: RESOURCE_KINDS;
  refreshRate: number;
  hasEventContext: boolean;
  hasPlannedContext: boolean;
  settings?: ResourceSettings;
  hasDefaultSearches: boolean;
  hasSections: boolean;
  hasRegions: boolean;
  hasFeedback: boolean;
  expandSearchBox: boolean;
  clustered: boolean;
}

export interface Workspace {
  id: string;
  title: string;
  items: Resource[];
  wsKind: WORKSPACE_KINDS;
  wsType: WORKSPACE_TYPES;
  editable: boolean;
  arrangeable: boolean;
  notifiable?: boolean;
}

export interface StoreItem {
  title: string;
  order: string;
  wsType: string;
  wsKind: string;
}

export interface UpdateItem {
  id: string;
  title?: string;
  order?: string;
}

export interface DeleteItem {
  id: string;
}

export interface StoreResource {
  id: string;
  title: string;
  order?: string;
  resourceId?: string;
  resourceKind?: string;
}

export interface UpdateResource {
  id?: string;
  workspaceId: string;
  resourceId: string;
  order?: number;
  settings?: ResourceSettings;
}

export interface DeleteResource {
  id?: string;
  resourceId: string;
  resourceKind?: string;
}

const index = (): Promise<Workspace[]> => {
  return http.get('/ui/all/workspaces');
};

const store = (item: StoreItem): Promise<Workspace[]> => {
  return http.post('/ui/all/workspaces', item);
};

const update = (item: UpdateItem): Promise<Workspace[]> => {
  return http.put(
    `/ui/all/workspaces/${item.id}`,
    pick(item, ['title', 'order']),
  );
};

const del = (item: DeleteItem): Promise<Workspace[]> => {
  return http.del(`/ui/all/workspaces/${item.id}`);
};

const storeResource = (item: StoreResource): Promise<[Workspace]> => {
  return http.post(
    `/ui/all/workspaces/${item.id}/items`,
    pick(item, ['title', 'order', 'resourceId', 'resourceKind']),
  );
};

const updateResource = (item: UpdateResource): Promise<Resource[]> => {
  return http.put(
    `/ui/all/workspaces/${item.id}/items/${item.resourceId}`,
    pick(item, ['workspaceId', 'order', 'settings']),
  );
};

const deleteResource = (item: DeleteResource): Promise<Resource[]> => {
  if (item.resourceKind == 'QUERY') {
    http.del(`/queries/${item.resourceId}`);
  }
  return http.del(`/ui/all/workspaces/${item.id}/items/${item.resourceId}`);
};

export default {
  index,
  store,
  update,
  del,
  storeResource,
  updateResource,
  deleteResource,
};
